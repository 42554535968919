<template>
  <div class="echartsbackground">
    <div
      id="map"
      style="width:98%;height:320px;margin:1.5% auto;background:rgba(24,32,60,.5);padding-top:10px;"
    ></div>
    <div
      id="map1"
      style="width:98%;height:320px;margin:1.5% auto;background:rgba(24,32,60,.5);padding-top:10px;"
    ></div>
    <div
      id="map2"
      style="width:98%;height:320px;margin:1.5% auto;background:rgba(24,32,60,.5);padding-top:10px;"
    ></div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  data() {
    return {
      use: [],
      settime:''
    };
  },
  mounted() {
   this.settime = setInterval(() => {
      this.getuse();
   }, 10000);
   
    
  },
  beforeDestroy(){
    clearInterval(this.settime)
  },

  methods: {
     getuse(){
      this.$Http.get("wx/bigscreen/getMerchantsAdds").then(res=>{
        if(res.data.data != ''){
          this.use = res.data.data;
           this.myechrsone();
        }
      }).catch(error=>{})
    },
    myechrsone() {

      let myechrs = this.$echarts.init(document.getElementById("map")),myechrs1 = this.$echarts.init(document.getElementById("map1")),myechrs2 = this.$echarts.init(document.getElementById("map2"))
      myechrs.setOption(this.opction(this.use[0]));
      myechrs1.setOption(this.opction(this.use[1]));
      myechrs2.setOption(this.opction(this.use[2]));
    },
    opction(val){
      let time = [],today=[],yestoday=[];
      val.today.filter(item=>{
        //  console.log(item.addtime.slice(14,19))
          time.push(item.addtime.slice(14,19))
          today.push(item.yeHeight)
         });
      val.yestoday.filter(item=>{
           yestoday.push(item.yeHeight)
         })
      let option = {
        title: {
          text: val.sn,
          textStyle: {
            fontSize: 20,
            color: "#00fff6",
          },
        },
        tooltip: {
          trigger: "axis",
          textStyle: {
            color: "#00fff6",
          },
        },
        legend: {
          data: [
            {
              name: "今日用量",
              textStyle: {
                color: "#00fff6",
              },
            },
            {
              name: "昨日用量",
              textStyle: {
                color: "#00fff6",
              },
            },
          ],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: time,
          axisLabel: {
            interval: 0,
            showMaxLabel: true, //显示最大刻度值
            color: "#00fff6",
          },
          axisLine: {
            lineStyle: {
              color: "#00fff6", //设置月份的颜色
              width: 1,
              type: "solid",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            interval: 0,
            showMaxLabel: true, //显示最大刻度值
            color: "#00fff6",
          },
          axisLine: {
            lineStyle: {
              color: "#00fff6", //设置月份的颜色
              width: 1,
              type: "solid",
            },
          },
          splitLine: {
            lineStyle: {
              color: "rgba(0, 255, 255, 1)",
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: "昨日用量",
            type: "line",
            stack: "总量",
            data: yestoday,
            //             lineStyle: {
            //   color: "rgba(124, 255, 81, 1)"
            // }
          },
          {
            name: "今日用量",
            type: "line",
            stack: "总量",
            data: today,
            //          lineStyle: {
            //   color: "rgba(124, 15, 81, 1)"
            // }
          },
        ],
      };
      return option
    }
  },
};
/* eslint-enable */
</script>
<style scoped>
.echartsbackground {
  width: 100%;
  height: 100%;
  padding: 0.2rem 0.2rem 0 0.2rem;
  background-image: url("../assets/pageBg.png");
  background-size: cover;
  background-position: center center;
  color: aliceblue;
}
</style>
